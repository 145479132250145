<template>
  <div class="selection-container o-row jc-center ai-center">
      <Card class="mr-2 ml-2 clickable" style="width: 31%;" @click="setSustainability">
          <template #header>
              <img :src="sustainability"/>
          </template>
          <template #title>
            <div class="title">
              Sustainability
            </div>
          </template>
          <template #subtitle>
            <div class="subtitle">
              Visibility on the upstream supply chain
            </div>
          </template>
      </Card>
      <Card class="mr-2 ml-2 clickable" style="width: 31%" @click="setPerformance">
          <template #header>
              <img :src="performance" />
          </template>
          <template #title>
            <div class="title">
              Performance
            </div>
          </template>
          <template #subtitle>
            <div class="subtitle">
              Real-time production and supply chain efficiency
            </div>
          </template>
      </Card>
      <Card class="mr-2 ml-2 clickable" style="width: 31%" @click="setCompliance">
          <template #header>
              <img :src="compliance" />
          </template>
          <template #title>
            <div class="title">
              Compliance
            </div>
          </template>
          <template #subtitle>
            <div class="subtitle">
              Product identification and tracking for traceability and compliance
            </div>
          </template>
      </Card>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    
  },
  methods: {
    setSustainability() {
      this.$bus.emit('set-category', ["sustainability", "sourcing"]);
    },
    setPerformance() {
      this.$bus.emit('set-category', ['performance', "inventory"]);
    },
    setCompliance() {
      //this.$bus.emit('set-category', 'compliance');
    },
  },
  computed: {
      performance() {
          return 'assets/performance.jpg';
      },
      sustainability() {
          return 'assets/sustainability.jpg';
      },
      compliance() {
          return 'assets/compliance.jpg';
      },
  }
}
</script>
<style lang="scss" scoped>
.selection-container {
    height: 100vh;
    width: 100vw;
}
.title {
  font-size: 150%;
}
.subtitle {
  font-size: 130%;
  height: 30px;
}
</style>
