<template>
  <div class="app-main">
    <AuthGoogle>
      <div class="app-header">
        <img
          alt="Traceability Platform"
          class="left-logo ml-3 clickable"
          :src="logoOTP"
          @click="goToLanding"
        />
        <div class="main-search-box flex-grow-1">
          <div class="p-input-icon-left main-search-container">
            <i class="pi pi-search" />
            <InputText
              type="text"
              v-model="searchString"
              placeholder="Search"
              @input="searchEvent"
            />
            <Dialog
              v-model:visible="searchVisible"
              position="top"
              :showHeader="false"
              :style="{
                width: '100%',
                'max-width': '900px',
                'max-height': '80vh',
                'margin-top': '50px',
              }"
            >
              <div v-if="!searchString">
                Please enter a search term in the field above to search for
                something
              </div>
              <div v-else-if="searching">Searching...</div>
              <div v-else-if="searchResults">
                <div v-if="searchLots.length">
                  <div class="search-result-section">Top Lot # Matches</div>
                  <DataTable :value="searchLots">
                    <Column field="lot" header="Lot #">
                      <template #body="{ data }">
                        {{ data.lot }}
                      </template>
                    </Column>
                    <Column field="details.product.id" header="Material">
                      <template #body="{ data }">
                        {{ idToString(data.details.product.id) }}
                      </template>
                    </Column>
                    <Column field="details.product.name" header="Product" />
                    <Column field="used" header="Used In">
                      <template #body="{ data }">
                        <div v-if="data.used.length">
                          <div
                            v-for="(l, i) of data.used"
                            class="clickable"
                            :key="i"
                            @click="goToLot(l, data.lot)"
                          >
                            {{ l }}
                          </div>
                        </div>
                        <div v-else>N/A</div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div v-if="searchMaterials.length">
                  <div class="search-result-section">Top Material Matches</div>
                  <DataTable :value="searchMaterials">
                    <Column field="material" header="Material" />

                    <Column header="Variants">
                      <template #body="{ data }">
                        <div
                          v-for="(m, i) of data.material_details.names"
                          :key="i"
                        >
                          {{ m }}
                        </div>
                      </template>
                    </Column>
                    <Column field="supplier.name" header="Supplier">
                      <template #body="{ data }">
                        <div
                          class="clickable"
                          @click="goToCompany(data.supplier.id)"
                        >
                          {{ data.supplier.name }}
                        </div>
                      </template>
                    </Column>
                    <Column header="Consumers">
                      <template #body="{ data }">
                        <div
                          v-for="(c, i) of data.buyers"
                          :key="i"
                          class="clickable"
                          @click="goToCompany(c.id)"
                        >
                          {{ c.name }}
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div v-if="searchSuppliers.length">
                  <div class="search-result-section">Top Supplier Matches</div>
                  <DataTable :value="searchSuppliers">
                    <Column field="name" header="Company">
                      <template #body="{ data }">
                        <div class="clickable" @click="goToCompany(data.id)">
                          {{ data.name }}
                        </div>
                      </template>
                    </Column>
                    <Column field="type" header="Production Type">
                      <template #body="{ data }">
                        {{ data.type }}
                      </template>
                    </Column>
                    <Column field="type" header="Supplier Type">
                      <template #body="{ data }">
                        <span
                          v-for="(c, i) of data.types"
                          :class="'mr-2 tag ' + c.style"
                          :key="i"
                          >{{ c.text }}</span
                        >
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div v-if="searchTickets.length">
                  <div class="search-result-section">Top Ticket Matches</div>
                  <DataTable :value="searchTickets">
                    <Column field="name" header="ID">
                      <template #body="{ data }">
                        <div class="clickable" @click="goToTicket(data.hid)">
                          {{ data.hid }}
                        </div>
                      </template>
                    </Column>
                    <Column field="type" header="Type">
                      <template #body="{ data }">
                        {{ data.type }}
                      </template>
                    </Column>
                    <Column field="type" header="Created">
                      <template #body="{ data }">
                        {{ new Date(data.created).toLocaleDateString() }}
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
              <div v-else>Nothing found for {{ searchString }}</div>
            </Dialog>
          </div>
        </div>
        <div class="top-menu mr-4 clickable" @click="$refs.user_menu.toggle">
          <div class="username">{{ $auth.state.idTokenParsed.email }}</div>
          <img
            v-if="$auth.state.idTokenParsed.picture"
            class="user-button"
            :src="$auth.state.idTokenParsed.picture"
          />
          <i v-else class="pi pi-user user-button" />
        </div>
        <Menu id="user_menu" ref="user_menu" :model="userMenu" :popup="true" />
      </div>
      <div v-if="category" class="app-body">
        <div v-if="viewWidth > 1100" class="app-side-bar">
          <div class="side-menu">
            <PanelMenu :model="appMenu" v-model:expandedKeys="expandedKeys" />
          </div>
          <div class="side-logos mb-5">
            <img
              v-if="partnerLogo"
              alt="Traceability Partner"
              class="bv-logo logo"
              :src="partnerLogo"
            />
            <img alt="Optel Group" class="optel-logo logo" :src="logoOptel" />
          </div>
        </div>
        <div v-else class="app-side-bar small">
          <div class="side-menu small">
            <PanelMenu
              :model="
                appMenu.map((m) => {
                  return { ...m, label: '' };
                })
              "
              v-model:expandedKeys="expandedKeys"
            />
          </div>
        </div>
        <div :class="(viewWidth > 1100 ? '' : 'small ') + 'app-content'">
          <router-view />
        </div>
      </div>
      <div v-else class="app-body">
        <router-view />
      </div>
    </AuthGoogle>
  </div>
</template>

<script>
import AuthGoogle from "@/components/auth/AuthGoogle";
import {
  fakeSearchLot,
  fakeSearchMaterials,
  fakeSearchSuppliers,
  decodeLot,
  idToString,
} from "./services/interface";
import { getFromRpc } from "./services/api";

export default {
  name: "App",
  components: {
    AuthGoogle,
  },
  data() {
    return {
      viewWidth: window.visualViewport.width,
      expandedKeys: {},
      category: null,
      isLoggedIn: false,
      tstate: null,
      searching: false,
      searchString: "",
      searchResults: 0,
      searchVisible: false,
      searchDebounce: null,
      searchMaterials: [],
      searchLots: [],
      searchSuppliers: [],
      searchTickets: [],
      userMenu: [
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          command: this.logOut,
        },
      ],
      appMenu: [
        {
          label: "Sustainability",
          key: "sustainability",
          items: [
            {
              label: "Responsible Sourcing",
              key: "sourcing",
              items: [
                {
                  label: "Dashboard",
                  icon: "pi pi-chart-line",
                  to: "/dash",
                },
                {
                  label: "Suppliers Qualification",
                  icon: "pi pi-map-marker",
                  to: "/supply",
                },
              ],
            },
            {
              label: "Carbon Footprint Tracking",
              icon: "fas fa-balance-scale",
            },
            {
              label: "Recycling",
              icon: "fas fa-recycle",
            },
          ],
        },
        {
          label: "Performance",
          key: "performance",
          items: [
            {
              label: "Inventory Tracking Planning",
              key: "inventory",
              items: [
                {
                  label: "Inventory GeoTracking",
                  icon: "fas fa-box",
                  to: "/inventory",
                },
                {
                  label: "Production Map",
                  icon: "pi pi-sitemap",
                  to: "/production",
                },
              ],
            },
            {
              label: "Product Quality",
              icon: "fas fa-file-invoice",
              to: "/vo/quality-management",
            },
            {
              label: "Product Authentication",
              icon: "fas fa-fingerprint",
            },
          ],
        },
        {
          label: "Compliance",
          key: "compliance",
          items: [
            {
              label: "Pharmaceutical Track and Trace",
              icon: "fas fa-qrcode",
            },
            {
              label: "Tax Management",
              icon: "fas fa-tags",
            },
            {
              label: "Food Safety",
              key: "food-safety",
              icon: "fas fa-carrot",
              to: "/vo/traceability-test"
            },
          ],
        },
        {
          label: "Master Data",
          icon: "pi pi-book",
        },
        {
          label: "Messages",
          icon: "pi pi-inbox",
          key: "messages",
          items: [
            {
              label: "Inbound",
              key: "inbound",
              icon: "fas fa-sign-in-alt",
              to: "/vo/messages-inbound",
            },
            /* {
              label: "Outbound",
              key: "outbound",
              icon: "fas fa-sign-out-alt",
              to: "/vo/messages-outbound",
            },*/
          ],
        },
        {
          label: "Alarms and Issues",
          icon: "pi pi-exclamation-triangle",
          to: "/tickets",
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.processWidth);
    this.$bus.on("set-category", this.setCategory);
  },
  unmounted() {
    this.$bus.off("set-category", this.setCategory);
  },
  mounted() {
    this.processCategory();
  },
  methods: {
    processCategory() {
      this.category = this.$router.options.routes.find(
        (r) => r.path === this.$router.currentRoute.value.path
      )?.cat;
      this.setCategory(this.category);
    },
    setCategory(cat) {
      Object.keys(this.expandedKeys).forEach(
        (k) => (this.expandedKeys[k] = false)
      );
      cat?.forEach((c) => (this.expandedKeys[c] = true));
      if (cat?.includes("sustainability")) {
        if (cat?.includes("sourcing")) {
          if (
            !this.$router.options.routes
              .filter((r) => r.cat?.sort().join() === cat?.sort().join())
              .map((r) => r.path)
              .includes(this.$router.currentRoute.value.path)
          ) {
            this.$router.push({ path: "/dash" });
          }
        }
      } else if (cat?.includes("performance")) {
        if (
          !this.$router.options.routes
            .filter((r) => r.cat?.sort().join() === cat?.sort().join())
            .map((r) => r.path)
            .includes(this.$router.currentRoute.value.path)
        ) {
          this.$router.push({ path: "/inventory" });
        }
      } else if (cat?.includes("compliance")) {
        if (
          !this.$router.options.routes
            .filter((r) => r.cat?.sort().join() === cat?.sort().join())
            .map((r) => r.path)
            .includes(this.$router.currentRoute.value.path)
        ) {
          this.$router.push({ path: "/about" });
        }
      }
    },
    idToString(id) {
      return idToString(id);
    },
    hideSearch() {
      this.searchVisible = false;
    },
    searchEvent() {
      this.searching = true;
      this.searchResults = 0;
      if (!this.searchVisible) {
        this.searchVisible = true;
      }
      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce);
      }
      this.searchDebounce = setTimeout(this.doSearch, 400);
    },
    doSearch() {
      this.searchDebounce = null;
      this.searchResults = 0;
      const slp = new Promise((resolve) => {
        fakeSearchLot(this.searchString).then((sl) => {
          this.searchLots = sl;
          this.searchResults += sl.length;
          resolve();
        });
      });
      const smp = new Promise((resolve) => {
        fakeSearchMaterials(this.searchString).then((sm) => {
          this.searchMaterials = sm;
          this.searchResults += sm.length;
          resolve();
        });
      });
      const ssp = new Promise((resolve) => {
        fakeSearchSuppliers(this.searchString).then((ss) => {
          this.searchSuppliers = ss;
          this.searchResults += ss.length;
          resolve();
        });
      });
      const stp = new Promise((resolve) => {
        getFromRpc("searchTickets", [this.searchString]).then((st) => {
          this.searchTickets = st;
          this.searchResults += st.length;
          resolve();
        });
      });
      Promise.all([slp, smp, ssp, stp]).then(() => {
        this.searching = false;
      });
    },
    goToLot(lot, material_lot) {
      decodeLot(material_lot).then((ml) => {
        decodeLot(lot).then((l) => {
          this.$router.push({
            path: "/production",
            query: { dates: [ml.date, l.date], lot, material_lot },
          });
          this.searchVisible = false;
        });
      });
    },
    goToCompany(company) {
      this.$router.push({ path: "/supply", query: { company } });
      this.searchVisible = false;
    },
    goToTicket(ticket) {
      this.$router.push({ path: "/tickets", query: { ticket } });
      this.searchVisible = false;
    },
    goToLanding() {
      this.$router.push({ path: "/landing" });
    },
    logOut() {
      this.$auth.bus.emit("logout");
    },
    processWidth() {
      this.viewWidth = window.visualViewport.width;
    },
  },
  computed: {
    logoOptel() {
      return "assets/OptelLogo.svg";
    },
    logoOptelWh() {
      return "assets/MonochromeOptelLogo.svg";
    },
    logoOTP() {
      return process.env.VUE_APP_PLATFORM_LOGO;
    },
    partnerLogo() {
      return process.env.VUE_APP_PARTNER_LOGO;
    },
  },
  watch: {
    $route() {
      this.processCategory();
    },
  },
};
</script>
<style lang="scss">
.fade-in {
  animation: fadeIn linear 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut linear 0.3s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

<style lang="scss" scoped>
.app-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.app-header {
  background-color: #003c71;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app-side-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  min-width: 200px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #f2f2f2;
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15) !important;

  &.small {
    max-width: 50px;
    width: 50px;
    min-width: 50px;
  }
}
.app-body {
  display: flex;
  height: calc(100vh - 50px);
  max-width: 100vw;
  width: 100vw;
}
.app-content {
  padding: 0;
  max-width: calc(100% - 200px);
  width: calc(100vw - 200px);
  flex: 1 2 auto;
  height: calc(100vh - 50px);
  overflow: none;

  &.small {
    min-width: calc(100% - 50px);
    max-width: calc(100% - 50px);
    width: calc(100vw - 50px);
  }
}
.search-result-section {
  font-size: 120%;
  font-weight: 600;
  margin-bottom: 5px;
}

.side-menu {
  padding-top: 20px;
  max-height: calc(100vh - 50px - 85px);
  overflow: hidden;
  &.small {
    width: 50px;
  }
}
.left-logo {
  height: 30px;
}
.side-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.optel-logo {
  width: 150px;
}

.bv-logo {
  width: 100px;
}
.logo {
  margin: 5px;
}
.top-menu {
  display: flex;
  align-items: center;
}
.username {
  color: #fff;
  font-weight: 600;
}
.user-button {
  color: #003c71;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 12px solid #003c71;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider {
  height: 35px;
  width: 2px;
  background-color: #fff;
  margin: 5px 20px;
}
.clickable {
  cursor: pointer;
}
.main-search-container {
  width: 100%;
}
.main-search-box {
  padding: 0px 50px;
  max-width: 600px;
}

.search-options {
  width: 100%;
  height: 600px;
  position: absolute;
}

:deep(.p-submenu-list) {
  border-bottom: 1px solid #d8d7d6;
}

:deep(.p-menuitem-link) {
  border-bottom: 1px solid #d8d7d6;
}

:deep(.p-menuitem-link:hover:not(.router-link-active-exact)) {
  background-color: #003c7130 !important;
}

:deep(.p-menuitem:last-child .p-menuitem-link) {
  border-bottom: none;
}

:deep(.p-panelmenu
    .p-panelmenu-content
    .p-submenu-list:not(.p-panelmenu-root-submenu)) {
  padding: 0px;
  box-shadow: inset 0 0 8px #00000030;
}

:deep(.p-panelmenu-root-submenu
    .p-menuitem
    .p-toggleable-content
    .p-submenu-list
    .p-menuitem
    .p-menuitem-link) {
  background-color: #003c7110;
  padding-left: 2rem;
}

:deep(.router-link-active-exact) {
  background-color: #003c71 !important;
  color: #fff !important;
}

:deep(.router-link-active-exact .p-menuitem-icon) {
  background-color: #003c71 !important;
  color: #fff !important;
}

:deep(.router-link-active-exact .p-menuitem-text) {
  background-color: #003c71 !important;
  color: #fff !important;
  font-weight: 500;
}

:deep(.p-input-icon-left > .p-inputtext) {
  width: 100%;
}
</style>
