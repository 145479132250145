import { createRouter, createWebHashHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Inventory from '../views/Inventory.vue'
import Production from '../views/Production.vue'
import Landing from '../views/Landing.vue'
import Supply from '../views/Supply.vue'
import Tickets from '../views/Tickets'
import VanillaOrigins from '../views/VanillaOrigins.vue'

const routes = [
  {
    path: '/dash',
    name: 'Dashboard',
    cat: ["sustainability", "sourcing"],
    component: Dashboard
  },
  {
    path: '/landing',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/inventory',
    name: 'Inventory',
    cat: ["performance", "inventory"],
    component: Inventory
  },
  {
    path: '/production',
    name: 'Production',
    cat: ["performance", "inventory"],
    component: Production
  },
  {
    path: '/supply',
    name: 'Supply',
    cat: ["sustainability", "sourcing"],
    component: Supply
  },
  {
    path: '/tickets',
    name: 'Tickets',
    cat: ["main"],
    component: Tickets
  },
  {
    path: '/vo/messages-inbound',
    name: 'Messages Inbound',
    cat: ["messages", "inbound"],
    component: VanillaOrigins,
    props: { target: "messages" }
  },
  {
    path: '/vo/messages-outbound',
    name: 'Messages Outbound',
    cat: ["messages", "outbound"],
    component: VanillaOrigins,
    props: { target: "messages/outbound" }
  },
  {
    path: '/vo/quality-management',
    name: 'Quality Management',
    cat: ["performance", "quality"],
    component: VanillaOrigins,
    props: { target: "quality-management/adherence-technical-list" }
  },
  {
    path: '/vo/traceability-test',
    name: 'Traceability Test',
    cat: ["compliance", "food-safety"],
    component: VanillaOrigins,
    props: { target: "tt" }
  },
  {
    path: '/about',
    name: 'About',
    cat: ["compliance"],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { path: '/', redirect: '/landing' },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
