<template>
    <Toast />
    <ProgressBar v-if="loading" mode="indeterminate" style="height: .5em" />
    <iframe class="guest-frame" id="guest" :src="target_host + target_path" />
</template>

<script>

export default {
    components: {
    },
	created() {
        window.addEventListener("message", this.processMessage);
    },
    unmounted() {
        window.removeEventListener("message", this.processMessage);
    },
    mounted() {
    },
    data() {
        return {
            target_host: process.env.VUE_APP_MAIN_FEATURESET_ORIGIN,
            target_path: process.env.VUE_APP_MAIN_FEATURESET_PATHPREFIX + '/inventory',
            loading: true,
        }
    },
	methods: {
        processMessage(message) {
            if (message.data.type === 'guestIsReady') {
                document.getElementById('guest').contentWindow.postMessage({code: 'idTokenChange', value: this.$auth.state.idToken}, this.target_host);
                this.loading = false;
            }
            if (message.data.type === 'tokenExpired') {
                this.$auth.bus.emit('logout');
                this.loading = true;
            }
        }
	},
	computed: {
	},
}
</script>

<style lang="scss" scoped>
@import '../assets/mixins/breakpoints.scss';

</style>