<template>
    <Toast />
    <div v-show="!isLoggedIn" class="grid full-screen">
        <div class="col-6 left-side">
            <div class="bottom-left">
                <div class="slogan"><img alt="Traceability Platform" class="left-logo" :src="logoOTP" /></div>
                <div class="rev-info">
                    <span>rev {{rev.toUpperCase()}}</span>
                </div>
            </div>
        </div>
        <div class="col-6 right-side">
            <div class="top-right">
                <div class="logo-box">
                    <img v-if="partnerLogo" alt="Traceability Partner" class="logo" :src="partnerLogo" />
                    <img alt="Optel Group" class="logo" :src="logoOptel" />
                </div>
                
                <div class="greet">Welcome!</div>
            </div>
            <div class="bottom-right">
                <div v-if="providers.includes('email')">
                    <span class="login-field"><i class="pi pi-user mr-2" /><InputText v-model="email" v-on:keyup.enter="startLogin({type:'email'})" style="width: 100%;" /></span>
                    <span class="login-field"><i class="pi pi-lock mr-2" /><Password v-model="password" :feedback="false" v-on:keyup.enter="startLogin({type:'email'})" :toggleMask="true" style="width: 100%;" inputStyle="width: 100%" /></span>
                    <Button label="Login" icon="fas fa-user-circle" class="login-field-button" @click="startLogin({type:'email'})"/>
                </div>
                <div v-if="Object.keys(loginOptions).length">
                    <div>You may sign-in using one of the SSO providers bellow :</div>
                    <Button v-for="(o, i) of loginOptions" :label="$t(o.name)" :icon="o.icon" class="login-button" @click="startLogin(o)" :key="i" />
                    <div id="recaptcha-container" />
                </div>
            </div>
        </div>
    </div>
    <div v-if="isLoggedIn">
        <slot></slot>
    </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth"
import { setToken } from '../../services/api';

firebase.initializeApp({
    apiKey: process.env.VUE_APP_GIDP_APIKEY,
    authDomain: process.env.VUE_APP_GIDP_AUTHDOMAIN
});

export default {
    name: 'AuthGoogle',
    data() {
        return {
            providers: process.env.VUE_APP_GIDP_PROVIDERS.split(',').map(p => p.trim()),
            email: null,
            password: null,
            loginOptions: {},
            isLoggedIn: false
        }
    },
    created() {
        const tstate = localStorage.getItem('auth_state');
        if (tstate) {
            this.$auth.state = JSON.parse(tstate);
        }
        else {
            this.$auth.state = {};
        }
        this.setupProviders();
        
        firebase.auth().tenantId = process.env.VUE_APP_GIDP_TENANT;

        firebase.auth().onAuthStateChanged(this.login);

        this.$auth.bus.on('logout', this.logout);
    },
    mounted() {

    },
    methods: {
        async login(user) {
            if (user) {
                this.$auth.state = {
                    idToken: await user.getIdToken()
                };
                this.$auth.state.isLoggedIn = this.isTokenValid();
                localStorage.setItem('auth_state', JSON.stringify(this.$auth.state));
                setToken(this.$auth.state.idToken);
                this.isLoggedIn = this.$auth.state.isLoggedIn;
            }
        },
        startLogin(o) {
            if (o.type === "email") {
                if (this.email && this.password) {
                    firebase.auth().signInWithEmailAndPassword(this.email, this.password).catch(() => {
                        this.$toast.add({severity:'warn', summary: 'Authentication Error', detail:'The login information provided is incorrect.', life: 9000});
                    });
                }
            }
            else {
                firebase.auth().signInWithPopup(o.provider);
            }
        },
        directLogin() {
            firebase.auth().signInWithEmailAndPassword(this.email, this.password).catch(e => {
                this.$toast.add({severity:'error', summary: 'Error Logging In', detail: e.code === 'auth/wrong-password'? "Incorrect credentials or unknown user. Please check and try again." : e.message, life: 9000});
            });
        },
        providerLogin(p) {
            firebase.auth().signInWithPopup(p);
        },
        logout() {
            firebase.auth().signOut()
            this.$auth.state.idToken = null;
            this.$auth.state.idTokenParsed = null;
            this.$auth.state.refreshToken = null;
            this.$auth.state.isLoggedIn = false;
            localStorage.setItem('auth_state', JSON.stringify(this.$auth.state));
            this.isLoggedIn = false;
        },
        isTokenValid() {
            // TODO : Validate the token signature...
            this.$auth.state.idTokenParsed = JSON.parse(Buffer.from(this.$auth.state.idToken.split('.')[1], 'base64').toString());

            if (this.$auth.state.idTokenParsed) {
                if (((this.$auth.state.idTokenParsed.exp * 1000) < Date.now()) ||
                    ((this.$auth.state.idTokenParsed.iat * 1000) > (Date.now() + 5000)) ||
                    (this.$auth.state.idTokenParsed.aud !== process.env.VUE_APP_AUTH_GCP_AUD) ||
                    (this.$auth.state.idTokenParsed.iss !== process.env.VUE_APP_AUTH_GCP_ISS) ||
                    (!this.$auth.state.idTokenParsed.sub)
                ) {
                    this.$toast.add({severity:'warn', summary: 'Authentication Error', detail:'The login information returned by your provider is incorrect. Please try again later.', life: 9000});
                    this.logout();
                    return false;
                }

                if (this.$auth.state.idTokenParsed.firebase.sign_in_provider === 'google.com' &&
                    !process.env.VUE_APP_GOOGLE_DOMAINS.split(',').map(d => d.trim()).includes(this.$auth.state.idTokenParsed.email.split('@')[1]) 
                ) {
                    this.$toast.add({severity:'warn', summary: 'No Authorized', detail:'You have successfully signed in, but your account is not authorized to access this platform.', life: 9000});
                    this.logout();
                    return false;
                }
                return true;
            }
            this.$toast.add({severity:'warn', summary: 'Error', detail:'No login information was returned by your provider. Please try again later.', life: 9000});
            return false;
        },
        setupProviders() {
            this.providers.forEach(p => {
                let provider = null;
                if (p === "email") {
                    provider = {
                        provider: new firebase.auth.EmailAuthProvider(),
                        type: p,
                        name: "Local Account",
                        icon: "fas fa-user-circle"
                    }
                }
                else if (p === "facebook") {
                    provider = {
                        provider: new firebase.auth.FacebookAuthProvider(),
                        type: p,
                        name: "Facebook",
                        icon: "fab fa-facebook"
                    }
                }
                else if (p === "github") {
                    provider = {
                        provider: new firebase.auth.GithubAuthProvider(),
                        type: p,
                        name: "GitHub",
                        icon: "fab fa-github"
                    }
                }
                else if (p === "google") {
                    provider = {
                        provider: new firebase.auth.GoogleAuthProvider(),
                        type: p,
                        name: "Google",
                        icon: "fab fa-google"
                    }
                }

                if (provider && p !== 'email') {
                    this.loginOptions[p] = provider;
                }

            })


        }
    },
    computed: {
        logoOptel() {
            return 'assets/OptelLogo.svg';
        },
        logoOptelWh() {
            return 'assets/MonochromeOptelLogo.svg';
        },
        logoOTP() {
            return process.env.VUE_APP_PLATFORM_LOGO;
        },
        partnerLogo() {
            return process.env.VUE_APP_PARTNER_LOGO;
        },
        rev() {
            return process.env.VUE_APP_GIT_HASH;
        }
    }
}
</script>

<style lang="scss" scoped>
.grid {
    margin: 0px;
}
.left-side {
    background-color: #003C71;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.bottom-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45vh;
}
.slogan {
    margin-left: 40px;
}
.rev-info {
    margin: 20px 40px;
    font-size: 80%;
    color: #FFF;
}
.right-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF;
}
.top-right {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.bottom-right {
    flex: 2 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.greet {
    font-weight: 700;
    color: #003C71;
    font-size: 200%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.full-screen {
    height: 100%;
    width: 100%;
}
.logo-box {
    height: 40%;
}
.logo {
    height: 100px;
    margin: 10px;
}
.left-optel-logo {
    height: 50px;
    filter: invert(100%)
}
.left-logo {
    width: 80%;
}
.divider {
    height: 35px;
    width: 2px;
    background-color: #FFF;
    margin: 5px 20px
}
.login-field {
    margin-top: 20px;
    width: 20vw;
    display: flex;
    align-items: center;
}
.login-field-button {
    background-color: #003C71;
    width: 20vw;
    font-size: 120%;
    font-weight: 700;
    padding: 15px 40px;
    margin-top: 20px;
}
.login-button {
    background-color: #003C71;
    width: 300px;
    font-size: 120%;
    font-weight: 700;
    padding: 15px 40px;
    margin: 20px;
}
</style>
