<template>
  <Toast />
  <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.5em" />
  <iframe class="guest-frame" id="guest" :src="targetUrl" />
</template>

<script>
export default {
  props: {
    target: String,
  },
  components: {},
  created() {
    window.addEventListener("message", this.processMessage);
  },
  unmounted() {
    window.removeEventListener("message", this.processMessage);
  },
  mounted() {},
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    processMessage(message) {
      if (message.data.type === "guestIsReady") {
        document
          .getElementById("guest")
          .contentWindow.postMessage(
            { code: "idTokenChange", value: this.$auth.state.idToken },
            process.env.VUE_APP_VANILLA_ORIGINS_ORIGIN
          );
        this.loading = false;
      }
      if (message.data.type === "tokenExpired") {
        this.$auth.bus.emit("logout");
        this.loading = true;
      }
    },
  },
  computed: {
    targetUrl() {
      return `${process.env.VUE_APP_VANILLA_ORIGINS_ORIGIN}#/iframe/${this.target}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/mixins/breakpoints.scss";
</style>