<template>
    <Toast />
    <ProgressBar v-if="loading" mode="indeterminate" style="height: .5em" />
    <iframe class="guest-frame" id="guest" :src="target_host + target_path" />
</template>

<script>

export default {
    components: {
    },
	created() {
        window.addEventListener("message", this.processMessage);
    },
    unmounted() {
        window.removeEventListener("message", this.processMessage);
    },
    mounted() {
        this.context = this.$route.query;
    },
    data() {
        return {
            target_host: process.env.VUE_APP_MAIN_FEATURESET_ORIGIN,
            target_path: process.env.VUE_APP_MAIN_FEATURESET_PATHPREFIX + '/supply',
            loading: true,
            context: null,
        }
    },
	methods: {
        processMessage(message) {
            if (message.data.type === 'guestIsReady') {
                document.getElementById('guest').contentWindow.postMessage({code: 'idTokenChange', value: this.$auth.state.idToken}, this.target_host);
                this.loading = false;
            }
            if (message.data.type === 'tokenExpired') {
                this.$auth.bus.emit('logout');
                this.loading = true;
            }
            if (message.data.type === 'goToLot') {
                this.$router.push({path:'/production',query: JSON.parse(message.data.args)});
                this.loading = true;
            }
            if (message.data.type === 'requestContext') {
                document.getElementById('guest').contentWindow.postMessage({code: 'setContext', value: JSON.stringify(this.context)}, this.target_host);
                this.loading = false;
            }
        }
	},
	computed: {
	},
    watch: {
        $route() {
            if (this.context !== this.$route.query) {
                this.context = this.$route.query;
                document.getElementById('guest').contentWindow.postMessage({code: 'setContext', value: JSON.stringify(this.context)}, this.target_host);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/mixins/breakpoints.scss';

</style>